import { ADDRESS_BASE_FRAGMENT } from '@/graphql/_Fragments/Address/Base';
import { EXHIBITOR_URL_BASE_FRAGMENT } from '@/graphql/_Fragments/ExhibitorUrl/Base';

export const EXHIBITOR_CONTACT_FRAGMENT = `
  fragment exhibitorContactFragment on Exhibitor {
    id
    uid
    name
    email
    addresses {
      ...addressBaseFragment
    }
    urls {
      ...exhibitorUrlBaseFragment
    }
    slots: _slots(filter: { code: "company_contact_info_slot" }) {
      code
      quantity
    }
  }
  ${EXHIBITOR_URL_BASE_FRAGMENT}
  ${ADDRESS_BASE_FRAGMENT}
`;
